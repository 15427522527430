//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-308:_660,_9224,_3284,_7464,_8664,_4180,_5688,_3036;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-308')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-308', "_660,_9224,_3284,_7464,_8664,_4180,_5688,_3036");
        }
      }catch (ex) {
        console.error(ex);
      }