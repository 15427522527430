
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function colorExtractorDialogRT () {
    return _createElement('div', { 'className': 'dialog-container' }, _createElement('div', {
        'className': 'close',
        'data-cm-role': 'close',
        'onClick': this.close
    }, _createElement('svg', {
        'className': 'cm_icon cm_icon-times',
        'height': '20px',
        'role': 'image',
        'viewBox': '0 0 22 22'
    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))), _createElement('div', { 'className': 'dialog-content' }, _createElement('div', { 'className': 'dialog-header' }, _createElement('div', { 'className': 'dialog-title' }, 'Select color(s)'), _createElement('div', { 'className': 'buttons-container' }, _createElement('div', {
        'className': 'cm_button cancel cm_button cm_button__secondary button-secondary',
        'onClick': this.close
    }, 'Cancel'), _createElement('div', {
        'className': 'cm_button submit cm_button cm_button__primary button-primary',
        'onClick': this.submit
    }, 'Ok'))), _createElement('div', { 'className': 'cm_colorExtractor dialog-body' }, '\n      ', this.image, '\n      ', _createElement('div', {}, 'Please, choose color(s) from your picture:'), '\n      ', this.colors, '\n    '), _createElement('div', { 'className': 'dialog-footer' }, _createElement('div', { 'className': 'dialog-footer-content' }, _createElement('div', {
        'className': 'cm_button cancel cm_button cm_button__secondary button-secondary',
        'onClick': this.close
    }, 'Cancel'), _createElement('div', {
        'className': 'cm_button submit cm_button cm_button__primary button-primary',
        'onClick': this.submit
    }, 'Ok')))));
}
        export const componentNames = []