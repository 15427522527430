//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-308:_352,_3879,_8564,_136,_3164,_7783,_996,_1624;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-308')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-308', "_352,_3879,_8564,_136,_3164,_7783,_996,_1624");
        }
      }catch (ex) {
        console.error(ex);
      }